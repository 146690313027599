<div *ngIf="gtinAttribute && !glnAttribute" class="verified-company" id="htmlData">
  <div class="row"  [ngClass]="{'row-header-completed':gtinAttribute && arrErrorMessages.length==0,
                              'row-header-discontinued': gtinAttribute?.gtinRecordStatus == 'DISCONTINUED', 
                              'row-header-error': arrErrorMessages.length>0 || gtinAttribute?.gtinRecordStatus == 'INACTIVE' || gtinAttribute?.HasInActive  }">    
    <div class="error-container" fxLayout="row">
      <!-- <div [innerHtml]=arrErrorMessages[0]></div> -->
      <img *ngIf="arrErrorMessages.length==0  && gtinAttribute && (gtinAttribute.gtinRecordStatus == 'OK' || gtinAttribute.gtinRecordStatus == 'DISCONTINUED' || gtinAttribute.HasOk)"
        src="{{cdnUrl}}/verified-barcode.svg" alt="GS1" class="pr-spacer-2 w-auto verifiedImage">
      <div class="sucess d-flex align-items-center" style="background-color: inherit;">
        <span 
        *ngIf="gtinAttribute && (gtinAttribute.gtinRecordStatus == 'OK' || gtinAttribute.gtinRecordStatus == 'DISCONTINUED' || gtinAttribute.HasOk) && arrErrorMessages.length==0"
        matTooltipPosition="right"
        matTooltip="{{'Verified_By_GS1_Checkmark_tooltip' | translate}}"
        aria-label="Button that displays a tooltip when focused or hovered over">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" [attr.fill]="gtinAttribute && gtinAttribute.gtinRecordStatus == 'OK' ? '#262626' : gtinAttribute && gtinAttribute.gtinRecordStatus == 'DISCONTINUED' ?
          '#262626' : gtinAttribute && gtinAttribute.gtinRecordStatus == 'INACTIVE' ? '#262626' : '#262626'" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
          </svg>
        </span>
        <div>
          <div *ngFor="let error of arrErrorMessages">            
            <p *ngIf="error!=' E039contactGS1'" class="label-error-data" >{{error}}</p>
              <p *ngIf="error==' E039contactGS1'" class="label-info-message" >{{'Please' |
                translate}} <a target="_blank" class="toulink" style="font-size: 0.95rem; font-weight: 500;color: #00799E;text-decoration: none;" (click)="clickSupportLink()">{{'contact_GS1' |
                  translate}}</a> {{'for_further_assistance' | translate}}</p>
          </div>


          <div *ngIf="CheckdigitErrorMessage && (CheckdigitErrorMessage?.CheckdigitCalculated || CheckdigitErrorMessage?.CheckdigitCalculated === 0)">
            <diV>
              <p class="label-error-data" >{{CheckdigitErrorMessage.CheckdigitErrorMessageKey | translate}}                
                <a class="link-gtin" (click)="onSearch()">
                  <span class="label-error-data-suggested">{{CheckdigitErrorMessage.GtinWithoutCheckDgit}}</span>
                  <span
                    class="label-error-data-suggested-underline">{{CheckdigitErrorMessage.CheckdigitCalculated}}
                  </span>
                  <span *ngIf="CheckdigitErrorMessage && CheckdigitErrorMessage.remainingdigits" class="label-error-data-suggested">{{CheckdigitErrorMessage.remainingdigits}}</span>
                </a>?
              </p>

              <p *ngIf="CheckdigitErrorMessage && CheckdigitErrorMessage.KeyName == 'gmn'" class="label-error-data-small"  >
                {{'for_more_information_on_how_to_calculate_these_digits' | translate }} 
                <a class="link-gtin" (click)="clickCheckdigitCalc(true)">
                  <span style="font-size: 0.95rem; font-weight: 500; display: inline-block;">
                    {{'check_character_calculator' | translate}}
                  </span></a>.
              </p>
              <p *ngIf="CheckdigitErrorMessage && CheckdigitErrorMessage.KeyName != 'gmn'"class="label-error-data-small"  >{{'error_checkdigit_calc_message' | translate}}
                <a class="link-gtin" (click)="clickCheckdigitCalc()">
                  <span style="font-size: 0.95rem; font-weight: 500; display: inline-block;">
                    {{'Check_Digit_Calculator'|translate}}</span></a>.
              </p>
            </diV>
          </div>

          <p class="label-info-message"  *ngIf="arrErrorMessages.length>0 && (arrErrorMessages.length == 1 || (arrErrorMessages.length >= 2 && arrErrorMessages[1] !=' E039contactGS1'))">{{'Please_check_the_typos_or' |
            translate}} <a target="_blank" class="contact" (click)="clickSupportLink()">{{'contact_GS1' |
              translate}}</a> {{'for_additional_support' | translate}}</p>
        </div>

        <div *ngIf="arrErrorMessages.length==0 && gtinAttribute"  class="d-flex align-items-center message-container" >
          
          <p class="label-complete-data-value" style="font-size: 18px !important; font-weight: 500;" 
          [ngStyle]="{'color': gtinAttribute.gtinRecordStatus == 'OK' ? '#026b30' : gtinAttribute.gtinRecordStatus == 'DISCONTINUED' ?
           '#9d6304' : gtinAttribute.gtinRecordStatus == 'INACTIVE' ? '#ba1b23' : 'black'}" >
            {{'This_number_is_registered_to_company' | translate}}
            <span style="font-size: 18px; color: #262626;">:</span>
           <strong style="font-size: 18px;" 
           [ngStyle]="{'color': gtinAttribute.gtinRecordStatus == 'OK' ? '#026b30' : gtinAttribute.gtinRecordStatus == 'DISCONTINUED' ?
            '#9d6304' : gtinAttribute.gtinRecordStatus == 'INACTIVE' ? '#ba1b23' : 'black'}">
             {{gtinAttribute.gs1Licence?.licenseeName}}</strong>
          </p>
          <p *ngIf="gtinAttribute.gtinRecordStatus == 'DISCONTINUED'" style="margin-top: 0 !important; margin-bottom: 0;color: #262626;">
            {{keyType == 'gtin' ? ('This_GTIN_record_is' | translate) : ('This_Record_Is' | translate) }} <b> {{'Discontinued_Status' | translate}}</b>. {{'This_record_is_no_longer_being_kept_up-to-date_by_the_original_manufacturer_or_distributor' | translate}}</p>
          <p *ngIf="gtinAttribute.gtinRecordStatus == 'INACTIVE' || gtinAttribute.keyRecordStatus == 'INACTIVE'" style="margin-top: 0 !important; margin-bottom: 0;color: #262626;">
            {{keyType == 'gtin' ? ('This_GTIN_record_is' | translate) : ('This_Record_Is' | translate) }} <b> {{'Inactive_Status' | translate}}</b>. {{'This_record_is_no_longer_being_kept_up-to-date_by_the_original_manufacturer_or_distributor' | translate}}</p>
          <p *ngIf="gtinAttribute.gtinRecordStatus != 'OK' && !gtinAttribute.HasOk" style="margin-top: 0 !important; margin-bottom: 0;color: #262626;">
            {{'Please' | translate}} <a (click)="clickSupportLink()" target="_blank" style="color: #00799e; text-decoration: none;">{{'contact_GS1' | translate}}</a> {{'for_additional_support' | translate}}</p>
          <!-- <p class="label-info-message" *ngIf="canDisplayAttributesSection">
          </p> -->
        </div>
      </div>  
    </div>
  </div>
</div>


<div *ngIf="glnAttribute  " class="verified-company" id="htmlData">
  <div class="row" [ngClass]="{
                              'row-header-completed': (glnAttribute.glnRecords && (this.glnAttribute.HasActive || this.glnAttribute.HasOk || (this.glnAttribute.glnRecords.length > 1 && this.glnAttribute.HasDiscontinued))) || this.glnAttribute.glnRecordStatus == 'OK', 
                              'row-header-discontinued': (glnAttribute.glnRecords && this.glnAttribute.HasDiscontinued && this.glnAttribute.glnRecords.length == 1),
                              'row-header-error': arrErrorMessages.length>0 || this.glnAttribute.HasInActive || this.glnAttribute.glnRecordStatus == 'INACTIVE' }">    
    <div class="error-container" fxLayout="row">
      <!-- <div [innerHtml]=arrErrorMessages[0]></div> -->
      <img *ngIf="arrErrorMessages.length==0  && glnAttribute && (glnAttribute.glnRecords && !this.glnAttribute.HasInActive && this.glnAttribute.glnRecordStatus != 'INACTIVE') || 
      (arrErrorMessages.length==0 && glnAttribute && !this.glnAttribute.HasInActive && this.glnAttribute.glnRecordStatus != 'INACTIVE') "
        src="{{cdnUrl}}/verified-barcode.svg" alt="GS1" class="pr-spacer-2 w-auto verifiedImage">
      <div class="sucess d-flex align-items-center" style="background-color: inherit;">
        <span 
        *ngIf="(arrErrorMessages.length==0 && glnAttribute.glnRecords && !this.glnAttribute.HasInActive  && this.glnAttribute.glnRecordStatus != 'INACTIVE') || (arrErrorMessages.length==0 && !this.glnAttribute.HasInActive  && this.glnAttribute.glnRecordStatus != 'INACTIVE')"
        matTooltipPosition="right"
        matTooltip="{{'The_Verified_By_GS1_Check_Tooltip' | translate}}"
        aria-label="{{'Button_That_Displays_A_Tooltip_When_Focused_Or_Hovered_Over' | translate}}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#262626" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
          </svg>
        </span>
        <div>
          <div *ngFor="let error of arrErrorMessages">
            <p *ngIf="error!=' E039contactGS1'" class="label-error-data" >{{error}}</p>
              <p *ngIf="error==' E039contactGS1'" class="label-info-message" >{{'Please' |
                translate}} <a target="_blank" class="toulink" (click)="clickSupportLink()">{{'contact_GS1' |
                  translate}}</a> {{'for_further_assistance' | translate}}</p>
          </div>


          <div *ngIf="CheckdigitErrorMessage && CheckdigitErrorMessage.CheckdigitCalculated">
            <diV>
              <p class="label-error-data" >{{CheckdigitErrorMessage.CheckdigitErrorMessageKey | translate}}                
                <a class="link-gtin" (click)="onSearch()">
                  <span class="label-error-data-suggested">{{CheckdigitErrorMessage.GtinWithoutCheckDgit}}</span>
                  <span
                    class="label-error-data-suggested-underline">{{CheckdigitErrorMessage.CheckdigitCalculated}}</span>
                </a>?
              </p>
              <p class="label-error-data-small" >{{'error_checkdigit_calc_message' | translate}}
                <a class="link-gtin" (click)="clickCheckdigitCalc()">
                  <span>{{'Check_Digit_Calculator'|translate}}</span></a>
              </p>
            </diV>
          </div>

          <p class="label-info-message"  *ngIf="arrErrorMessages.length>0 && (arrErrorMessages.length == 1 || (arrErrorMessages.length >= 2 && arrErrorMessages[1] !=' E039contactGS1'))">{{'Please_check_the_typos_or' |
            translate}} <a target="_blank" class="toulink" (click)="clickSupportLink()">{{'contact_GS1' |
              translate}}</a> {{'for_additional_support' | translate}}</p>
        </div>

        <div *ngIf="arrErrorMessages.length==0 && glnAttribute"  class="d-flex align-items-center message-container" >
          
          <p class="label-complete-data-value" style="font-size: 18px !important; font-weight: 500; color: #262626;" >
            {{'This_number_is_registered_to_company' | translate}}
            <span style="font-size: 18px !important; font-weight: 500; color: #262626;">:</span>
           <strong style="font-size: 18px; color: #262626;"> {{glnAttribute?.gs1Licence?.licenseeName}}</strong>
          </p>
          <p *ngIf="(glnAttribute.glnRecords && glnAttribute.glnRecords.length == 1 && this.glnAttribute.HasDiscontinued)" style="margin-top: 0 !important; margin-bottom: 0;color: #262626;">
            {{keyType == 'gln' ? ('This_GLN_Record_Is' | translate) : ('This_Record_Is' | translate)}} <b> {{'Discontinued_Status_not_operational' | translate}}</b>. {{'This_record_is_no_longer_being_kept_up-to-date_by_the_original_manufacturer_or_distributor' | translate}}</p>
          <p *ngIf="(glnAttribute.glnRecords && this.glnAttribute.HasInActive) ||  this.glnAttribute.glnRecordStatus == 'INACTIVE'" style="margin-top: 0 !important; margin-bottom: 0;color: #262626;">
            {{keyType == 'gln' ? ('This_GLN_Record_Is' | translate) : ('This_Record_Is' | translate)}} <b> {{'Inactive_Status' | translate}}</b>. {{'This_record_is_no_longer_being_kept_up-to-date_by_the_original_manufacturer_or_distributor' | translate}}</p>
          <p *ngIf="(glnAttribute.glnRecords && !this.glnAttribute.HasActive && !this.glnAttribute.HasOk && !this.glnAttribute.AllDiscontinued && this.glnAttribute.glnRecordStatus != 'OK') || this.glnAttribute.glnRecordStatus == 'INACTIVE'" style="margin-top: 0 !important; margin-bottom: 0;color: #262626;">
            {{'Please' | translate}} <a href="https://gs1.no/kontakt/" target="_blank" style="color: #00799e; text-decoration: none;">{{'contact_GS1' | translate}}</a> {{'for_additional_support' | translate}}</p>
          <!-- <p class="label-info-message" *ngIf="canDisplayAttributesSection">
          </p> -->
        </div>
      </div>  
    </div>
  </div>
</div>
